<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import { useSearchDialogStore } from '@/core/stores/searchDialog';

import TheSearchDialogMenuItems from './TheSearchDialogMenuItems.vue';
import { resolveSearchResults } from './utils';

const menu = useMenuStore();
const { accessTools } = storeToRefs(menu);

const searchDialog = useSearchDialogStore();
const { searchResults } = storeToRefs(searchDialog);

const accessToolsSpotlightResults = computed(() => {
  return resolveSearchResults(searchResults.value, accessTools.value);
});
</script>

<template>
  <div class="space-y-2">
    <div class="flex items-center gap-2.5 px-2 text-access-500">
      <SparkIcon icon="access" size="16" />
      <h4 class="h4 text-access-500">LNG Access</h4>
    </div>
    <div class="space-y-2">
      <TheSearchDialogMenuItems :menu-items="accessToolsSpotlightResults" />
    </div>
  </div>
</template>
