import 'fuzzysort';

import type { MenuItem, MenuItemBasic } from './types';

export function resolveSearchResult(
  results: Fuzzysort.KeysResults<MenuItemBasic>,
  menuItem: { title: string; id: string },
) {
  if (!results || results.total === 0) {
    return { spotlight: undefined, htmlTitle: menuItem.title };
  }

  const found = results.find((result) => result.obj.id === menuItem.id);

  if (!found) {
    return { spotlight: false, htmlTitle: menuItem.title };
  }
  const htmlTitle = found[0].highlight(
    '<span class="text-blue-800 bg-yellow-200">',
    '</span>',
  );
  return {
    htmlTitle,
    spotlight: true,
  };
}

export function resolveSearchResults(
  searchResults: Fuzzysort.KeysResults<MenuItemBasic>,
  menuItems: MenuItem[],
) {
  return menuItems.map((item) => {
    const { htmlTitle, spotlight: parentSpotlight } = resolveSearchResult(
      searchResults,
      item,
    );

    const subItems = item.items?.map((subItem) => {
      const { htmlTitle, spotlight: childSpotlight } = resolveSearchResult(
        searchResults,
        subItem,
      );

      const spotlight = parentSpotlight === true ? true : childSpotlight;
      return {
        ...subItem,
        htmlTitle,
        spotlight,
      };
    });

    return {
      ...item,
      htmlTitle,
      spotlight: parentSpotlight,
      items: subItems,
    };
  });
}
