<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    to: string;
    icon: string;
    isLocked?: boolean;
    iconSize?: string;
    spotlight?: boolean;
  }>(),
  {
    isLocked: false,
    iconSize: '16',
    spotlight: undefined,
  },
);
const [DefineTemplate, ReuseTemplate] = createReusableTemplate();
const isExternal = computed(() => props.to.startsWith('http'));
</script>
<template>
  <DefineTemplate v-slot="{ href, clickHandler, external }">
    <a
      :href="href"
      :target="external ? '_blank' : undefined"
      :data-spotlight="spotlight"
      :data-lock="isLocked"
      v-bind="$attrs"
      class="flex items-center gap-2 rounded px-2 py-1 text-sm text-gray-400 transition-[color,opacity] hover:bg-gray-700/50 hover:text-gray-100 data-[lock=true]:text-gray-600 data-[spotlight=false]:opacity-30"
      @click="(e) => clickHandler?.(e)"
    >
      <SparkIcon :icon="isLocked ? 'lock' : icon" :size="iconSize" />
      <slot />
    </a>
  </DefineTemplate>
  <RouterLink v-if="!isExternal" v-slot="{ href, navigate }" :to="to" custom>
    <ReuseTemplate :href="href" :click-handler="navigate" />
  </RouterLink>

  <ReuseTemplate v-else :href="to" :external="true"></ReuseTemplate>
</template>
