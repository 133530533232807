<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useSearchDialogStore } from '@/core/stores/searchDialog';

import TheTopMenuTriggerButton from './TheTopMenuTriggerButton.vue';

const searchDialogStore = useSearchDialogStore();

const { open } = storeToRefs(searchDialogStore);

function onMenuButtonClicked() {
  searchDialogStore.toggleOpen({ fromMobile: true });
}
</script>

<template>
  <Teleport to="#mobile-view-top-menu">
    <div
      v-if="open"
      class="fixed inset-0 bg-transparent"
      @click.self="open = false"
    ></div>
    <div
      class="fixed top-0 w-full bg-blue-900 data-[open=true]:shadow-2xl hd:hidden"
      data-id="top-menu"
      :data-open="open"
    >
      <TheTopMenuTriggerButton :open="open" @click="onMenuButtonClicked" />
    </div>
  </Teleport>
</template>
