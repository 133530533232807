<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  VisuallyHidden,
} from 'radix-vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useSearchDialogStore } from '@/core/stores/searchDialog';
import { assertIsDefined } from '@/shared/misc/typescriptUtils';
import SparkIcon from '#/components/generic/SparkIcon';
import { getOS } from '#/utils/browser';
import { isNotEmptyText } from '#/utils/string';

import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';
import TheSearchDialogAccessMenu from './TheSearchDialogAccessMenu.vue';
import TheSearchDialogCargoMenu from './TheSearchDialogCargoMenu.vue';
import TheSearchDialogFreightMenu from './TheSearchDialogFreightMenu.vue';
import TheSearchDialogMiscSection from './TheSearchDialogMiscSection.vue';

const searchDialogStore = useSearchDialogStore();
const route = useRoute();

const { searchTerm, open, searchResults, isNavigating } =
  storeToRefs(searchDialogStore);

const topSection = ref<HTMLElement | null>(null);
const showLogout = ref(false);
const { height: topSectionHeight } = useElementSize(topSection);
const os = getOS() as 'macos' | 'windows' | null;
const SHORT_CUT_KEYS = {
  macos: '⌘ K',
  windows: 'Ctrl K',
} as const;

const shortcut = (os && SHORT_CUT_KEYS[os]) ?? '';

const context = inject(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY);
assertIsDefined(
  context,
  'TheSearchDialog requires TheSidebarMainMenu as parent',
);
const { isExpanded } = context;

const noResultsFound = computed(
  () => isNotEmptyText(searchTerm.value) && searchResults.value.length === 0,
);

const bottomSectionStyle = computed(() => {
  return {
    maxHeight: `calc(100svh - ${topSectionHeight.value}px - 2rem)`,
  };
});

searchDialogStore.triggerFromMobileBus.on(() => {
  showLogout.value = true;
});

function onKeyDown(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    searchDialogStore.goToOnlyResult();
  }
}

function onMenuParentClicked(e: MouseEvent) {
  const linkElement = e
    .composedPath()
    .find((el) => el instanceof HTMLAnchorElement) as
    | HTMLAnchorElement
    | undefined;
  if (linkElement) {
    const url = new URL(linkElement.href);
    if (url.pathname === route.path) {
      open.value = false;
    }
  }
}

onMounted(() => {
  document.body.addEventListener('keydown', (e) => {
    // cmd + k
    if (e.metaKey && e.key === 'k') {
      e.preventDefault();
      open.value = !open.value;
    }
  });
});

watch(open, (openValue) => {
  if (openValue) {
    document.getElementById('app')?.setAttribute('inert', '');
  } else {
    document.getElementById('app')?.removeAttribute('inert');
    searchTerm.value = '';
    showLogout.value = false;
  }
});
</script>

<template>
  <DialogRoot v-model:open="open">
    <DialogTrigger
      class="col-span-2 grid h-11 grid-cols-[subgrid] text-gray-400 transition-colors hover:bg-gray-700/50 hover:text-gray-100 focus:outline-none"
    >
      <div class="flex h-full items-center justify-center">
        <SparkIcon icon="search" size="20" />
      </div>
      <div
        class="flex h-full w-[calc(100px-1rem)] items-center overflow-hidden text-sm font-medium leading-none opacity-0 transition-opacity data-[expanded=true]:opacity-100"
        :data-expanded="isExpanded"
      >
        <span>Search</span>
        <span class="ml-auto text-gray-600">{{ shortcut }}</span>
      </div>
    </DialogTrigger>

    <DialogPortal to="#search-dialog">
      <DialogOverlay
        class="fixed inset-0 bg-blue-900/0 transition-colors data-[state=open]:bg-blue-900/70"
      />
      <DialogContent
        id="search-dialog-content"
        class="fixed inset-0 h-full w-full @container"
      >
        <VisuallyHidden>
          <DialogTitle>Search</DialogTitle>
          <DialogDescription>Dialog Description</DialogDescription>
        </VisuallyHidden>

        <div
          class="h-full @hd:flex @hd:items-center @hd:justify-center"
          @click.self="open = false"
        >
          <div
            class="max-h-[100svh] w-full @hd:max-w-[710px]"
            @click="onMenuParentClicked"
          >
            <div
              class="rounded-xl bg-blue-900 px-4 py-4 text-gray-400 @hd:px-8"
            >
              <div class="relative">
                <div class="space-y-4">
                  <div ref="topSection" class="space-y-4">
                    <div class="flex gap-x-4">
                      <div class="flex grow items-center gap-4">
                        <div class="flex grow items-center gap-4">
                          <SparkIcon icon="search" size="18" />
                          <input
                            v-model="searchTerm"
                            type="text"
                            class="w-full border-0 bg-blue-900 font-medium text-gray-300 placeholder:font-medium placeholder:text-gray-700 focus:outline-none focus:ring-0"
                            placeholder="Search a contract, tool or port..."
                            @keydown="onKeyDown"
                          />
                        </div>
                        <div
                          v-if="noResultsFound"
                          class="rounded bg-red-500 px-1 text-xs font-semibold text-white"
                        >
                          No results found!
                        </div>
                      </div>
                      <DialogClose
                        class="flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-800"
                      >
                        <SparkIcon icon="close" size="18" />
                      </DialogClose>
                    </div>
                    <hr class="border-t border-gray-800" />
                    <TheSearchDialogMiscSection :show-logout="showLogout" />
                    <hr class="border-t border-gray-800" />
                  </div>
                  <div class="overflow-y-auto" :style="bottomSectionStyle">
                    <div
                      class="grid gap-4 pb-4 @hd:grid-cols-[1fr_auto_1fr_auto_1fr]"
                    >
                      <TheSearchDialogFreightMenu />
                      <div
                        class="border-t border-gray-800 @hd:h-full @hd:border-0 @hd:border-l"
                      ></div>
                      <TheSearchDialogCargoMenu />
                      <div
                        class="border-t border-gray-800 @hd:h-full @hd:border-0 @hd:border-l"
                      ></div>
                      <TheSearchDialogAccessMenu />
                    </div>
                  </div>
                </div>
                <div
                  v-if="isNavigating"
                  class="absolute inset-0 flex items-center justify-center bg-blue-900/80"
                >
                  <SparkIcon
                    icon="spinner"
                    size="48"
                    :spin="isNavigating"
                    class="text-green-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
