<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import { useSearchDialogStore } from '@/core/stores/searchDialog';

import TheSearchDialogLink from './TheSearchDialogLink.vue';
import TheSearchDialogMenuItems from './TheSearchDialogMenuItems.vue';
import TheSearchDialogMenuItemWithSubItems from './TheSearchDialogMenuItemWithSubItems.vue';
import { resolveSearchResults } from './utils';

const menu = useMenuStore();
const { cargoContracts, cargoTools } = storeToRefs(menu);

const searchDialog = useSearchDialogStore();
const { searchResults } = storeToRefs(searchDialog);

const cargoContractsSpotlightResults = computed(() => {
  return resolveSearchResults(searchResults.value, cargoContracts.value);
});

const cargoToolsSpotlightResults = computed(() => {
  return resolveSearchResults(searchResults.value, cargoTools.value);
});
</script>

<template>
  <div class="space-y-2">
    <div class="flex items-center gap-2 px-2 text-basis-400">
      <SparkIcon icon="basis" size="18" />
      <h4 class="h4 text-basis-400">LNG Cargo</h4>
    </div>

    <div class="grid grid-cols-2 gap-1">
      <template
        v-for="item in cargoContractsSpotlightResults"
        :key="item.title"
      >
        <TheSearchDialogMenuItemWithSubItems
          v-if="!item.to && item.items?.length"
          :item="item"
        />

        <!-- item with no sub items. ie SparkClose -->
        <template v-if="item.to && !item.items">
          <TheSearchDialogLink
            class="@hd:col-span-2"
            :to="item.to"
            :icon="item.icon"
            :is-locked="item.isLocked"
            :spotlight="item.spotlight"
            ><span v-html="item.htmlTitle"></span>
            <div
              v-if="item.subTitle"
              class="mt-1 text-[0.7rem] italic text-orange"
            >
              {{ item.subTitle }}
            </div></TheSearchDialogLink
          >
        </template>
      </template>
    </div>

    <div class="grid grid-cols-2 @hd:grid-cols-1">
      <TheSearchDialogMenuItems :menu-items="cargoToolsSpotlightResults" />
    </div>
  </div>
</template>
