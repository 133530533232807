<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import { useSearchDialogStore } from '@/core/stores/searchDialog';

import {
  accountFeatures,
  dashboardMenuItem,
  miscFeatures,
} from './mainMenuConfig';
import TheSearchDialogLink from './TheSearchDialogLink.vue';
import { resolveSearchResult } from './utils';

const props = defineProps<{
  showLogout: boolean;
}>();

const menu = useMenuStore();
const searchDialog = useSearchDialogStore();

const { crossProductFeaturesMenu } = storeToRefs(menu);
const { searchResults } = storeToRefs(searchDialog);

const dashboardResult = computed(() => {
  const { htmlTitle, spotlight } = resolveSearchResult(
    searchResults.value,
    dashboardMenuItem,
  );
  return {
    ...dashboardMenuItem,
    htmlTitle,
    spotlight,
  };
});

const crossProductFeaturesSpotlightResults = computed(() => {
  return crossProductFeaturesMenu.value.map((x) => {
    const { htmlTitle, spotlight } = resolveSearchResult(
      searchResults.value,
      x,
    );
    return {
      ...x,
      htmlTitle,
      spotlight,
    };
  });
});

const miscFeaturesSpotlightResults = computed(() => {
  return miscFeatures.map((x) => {
    const { htmlTitle, spotlight } = resolveSearchResult(
      searchResults.value,
      x,
    );
    return {
      ...x,
      htmlTitle,
      spotlight,
    };
  });
});

const accountFeaturesSpotlightResults = computed(() => {
  return accountFeatures
    .filter((a) => {
      if (a.title === 'Logout') {
        return props.showLogout;
      } else {
        return true;
      }
    })
    .map((x) => {
      const { htmlTitle, spotlight } = resolveSearchResult(
        searchResults.value,
        x,
      );
      return {
        ...x,
        htmlTitle,
        spotlight,
      };
    });
});
</script>

<template>
  <div class="flex flex-wrap justify-between gap-y-1">
    <div class="flex gap-1.5">
      <TheSearchDialogLink
        :to="dashboardResult.to"
        :icon="dashboardResult.icon"
        :data-spotlight="dashboardResult.spotlight"
      >
        <span v-html="dashboardResult.htmlTitle"></span>
      </TheSearchDialogLink>
      <template
        v-for="item in crossProductFeaturesSpotlightResults"
        :key="item.title"
      >
        <TheSearchDialogLink
          v-if="item.to"
          :to="item.to"
          :icon="item.icon"
          :data-spotlight="item.spotlight"
        >
          <span v-html="item.htmlTitle"></span>
        </TheSearchDialogLink>
      </template>
    </div>
    <div class="flex gap-1.5">
      <template v-for="item in miscFeaturesSpotlightResults" :key="item.title">
        <TheSearchDialogLink
          v-if="item.to"
          :to="item.to"
          :icon="item.icon"
          :data-spotlight="item.spotlight"
        >
          <span v-html="item.htmlTitle"></span>
        </TheSearchDialogLink>
      </template>
      <template
        v-for="item in accountFeaturesSpotlightResults"
        :key="item.title"
      >
        <TheSearchDialogLink
          v-if="item.to"
          :to="item.to"
          :icon="item.icon"
          :data-spotlight="item.spotlight"
        >
          <span v-html="item.htmlTitle"></span>
        </TheSearchDialogLink>
      </template>
    </div>
  </div>
</template>
