<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useMenuStore } from '@/core/stores/menu';
import { useSearchDialogStore } from '@/core/stores/searchDialog';

import TheSearchDialogMenuItems from './TheSearchDialogMenuItems.vue';
import TheSearchDialogMenuItemWithSubItems from './TheSearchDialogMenuItemWithSubItems.vue';
import { resolveSearchResults } from './utils';

const menu = useMenuStore();
const { freightContracts, freightTools } = storeToRefs(menu);

const searchDialog = useSearchDialogStore();
const { searchResults } = storeToRefs(searchDialog);

const freightContractsSpotlightResults = computed(() => {
  return resolveSearchResults(searchResults.value, freightContracts.value);
});

const freightToolsSpotlightResults = computed(() => {
  return resolveSearchResults(searchResults.value, freightTools.value);
});
</script>

<template>
  <div class="space-y-2">
    <div class="flex items-center gap-2 px-2 text-green-500">
      <SparkIcon icon="freight" size="18" />
      <h4 class="h4 text-green-500">LNG Freight</h4>
    </div>
    <div class="grid grid-cols-2 gap-1">
      <template
        v-for="item in freightContractsSpotlightResults"
        :key="item.title"
      >
        <TheSearchDialogMenuItemWithSubItems
          v-if="!item.to && item.items?.length"
          :item="item"
        />
      </template>
    </div>
    <div class="grid grid-cols-2 @hd:grid-cols-1">
      <TheSearchDialogMenuItems :menu-items="freightToolsSpotlightResults" />
    </div>
  </div>
</template>
