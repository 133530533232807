import { useEventBus } from '@vueuse/core';
import fuzzysort from 'fuzzysort';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  accountFeatures,
  dashboardMenuItem,
  miscFeatures,
} from '../components/the-nav/mainMenuConfig';
import type { MenuItem, MenuItemBasic } from '../components/the-nav/types';
import { useMenuStore } from './menu';

const useSearchDialogStore = defineStore('searchDialog', () => {
  const menu = useMenuStore();
  const router = useRouter();
  const route = useRoute();
  const open = ref(false);
  const isNavigating = ref(false);

  const {
    freightContracts,
    freightTools,
    cargoContracts,
    cargoTools,
    accessTools,
  } = storeToRefs(menu);
  const { crossProductFeaturesMenu } = storeToRefs(menu);

  const searchTerm = ref('');

  const triggerFromMobileBus = useEventBus('triggerFromMobile');

  const dashboardMenuItemBasic: MenuItemBasic = {
    id: dashboardMenuItem.id,
    to: dashboardMenuItem.to,
    icon: dashboardMenuItem.icon,
    title: dashboardMenuItem.title,
  };

  const searchableFreightContracts = computed<MenuItemBasic[]>(() => {
    return toSearchableMenuItems(freightContracts.value);
  });

  const searchableFreightTools = computed<MenuItemBasic[]>(() => {
    return toSearchableMenuItems(freightTools.value);
  });

  const searchableCargoContracts = computed<MenuItemBasic[]>(() => {
    return toSearchableMenuItems(cargoContracts.value);
  });

  const searchableCargoTools = computed<MenuItemBasic[]>(() => {
    return toSearchableMenuItems(cargoTools.value);
  });

  const searchableAccessTools = computed<MenuItemBasic[]>(() => {
    return toSearchableMenuItems(accessTools.value);
  });

  const searchableMenu = computed(() => {
    return [
      dashboardMenuItemBasic,
      ...crossProductFeaturesMenu.value,
      ...searchableFreightContracts.value,
      ...searchableFreightTools.value,
      ...searchableCargoContracts.value,
      ...searchableCargoTools.value,
      ...searchableAccessTools.value,
      ...miscFeatures,
      ...accountFeatures,
    ];
  });

  const searchResults = computed<Fuzzysort.KeysResults<MenuItemBasic>>(() => {
    return fuzzysort.go(searchTerm.value, searchableMenu.value, {
      keys: ['title'],
      all: false,
      threshold: 0.5,
    });
  });

  function goToOnlyResult() {
    if (searchResults.value.length === 1) {
      const result = searchResults.value[0].obj;
      if (!result.isLocked) {
        if (result.to) {
          if (result.to.startsWith('http')) {
            window.open(result.to, '_newtab' + Date.now());
          } else {
            router.push(result.to);
          }
        }
      }
    }
  }

  function toSearchableMenuItems(menuItems: MenuItem[]) {
    const items: MenuItemBasic[] = [];

    menuItems.forEach((item) => {
      items.push({
        id: item.id,
        to: item.to,
        icon: item.icon,
        title: item.title,
        isLocked: item.isLocked,
        showRedDot: item.showRedDot,
      });

      if (item.items) {
        item.items.forEach((subItem) => {
          items.push({
            id: subItem.id,
            to: subItem.to,
            icon: item.icon,
            title: subItem.title,
            isLocked: item.isLocked,
            showRedDot: subItem.showRedDot,
          });
        });
      }
    });

    return items;
  }

  function toggleOpen({ fromMobile } = { fromMobile: false }) {
    if (fromMobile) {
      triggerFromMobileBus.emit();
    }
    open.value = !open.value;
  }

  router.beforeEach(() => {
    if (open.value) {
      isNavigating.value = true;
    }
  });

  watch(
    () => route.path,
    () => {
      if (open.value) {
        open.value = false;
        isNavigating.value = false;
      }
    },
  );

  return {
    triggerFromMobileBus,
    open,
    isNavigating,
    searchTerm,
    searchableMenu,
    searchResults,
    goToOnlyResult,
    toggleOpen,
  };
});

export { useSearchDialogStore };
